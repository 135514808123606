body,
html {
  font-family: "Noto Sans", sans-serif;
}
.margin-minus {
  margin-top: -101px;
}
.banner-sec h2 {
  font-size: 46px;
  font-weight: 400;
  line-height: 1.3;
}
@media (min-width: 768px) {
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-3 {
    padding-block: 10px;
  }
}
.sec-title {
  font-size: 64px;
  font-weight: 500;
  line-height: 70px;
}
.icons,
.pricing li,
p {
  font-size: 18px !important;
}
.b-r-6 {
  border-radius: 10px;
}
.design-block {
  width: 100%;
}
.bg-light-blue {
  background: #52bdc7;
}
.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1400px) {
  .custom-cn-width {
    max-width: 1320px;
  }
}
.react-slideshow-container .nav:last-of-type {
  right: 0;
}
.react-slideshow-container .nav {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  border: 0;
}
button.cs-btn {
  background: initial;
  color: #000;
}
button.cs-btn.btn-left.nav {
  margin-left: -50px;
}
.react-slideshow-container .nav:first-of-type {
  left: 0;
}
.react-slideshow-wrapper .images-wrap.horizontal {
  display: flex;
  flex-wrap: wrap;
}

#footer {
  background: #13303c;
  padding: 0;
  position: relative;
}
.themesflat-container {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
.header-inner,
.themesflat-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1320px;
}
.footer-bottom {
  border-top: none;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  padding-bottom: 20px;
  padding-top: 20px;
}
#footer p.text,
ul.wg-menu li a {
  color: #9a9c9e !important;
  font-size: 15px;
}
.footer-head p,
.social-icon-custm a {
  color: #fff9;
  font-size: 16px;
}
.social-icon-custm a:hover {
  color: #fff;
  cursor: pointer;
}
.react-slideshow-wrapper .images-wrap.horizontal {
  align-items: center;
}

.software-sec .bg-overlay {
  background-color: #f5f5f5;
  width: 100% !important;
  left: 0;
}
.site-logo {
  position: relative;
  z-index: 9;
}

header.header-style-1 {
  z-index: 9;
  position: relative;
}

p {
  font-size: 18px !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  outline: 0 !important;
}

.nav-tabs .nav-link {
  border: 0 !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: 0 !important;
  outline: 0 !important;
  border-bottom: 2px solid #0c5b79 !important;
  color: #0c5b79;
  font-weight: 600 !important;
}

:focus-visible {
  outline: 0 !important;
}

.nav-space {
  justify-content: space-between;
}
@media (max-width: 768px) {
  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }
  .px-sm-3 {
    padding-inline: 10px;
  }
}
@media screen and (max-width: 575px) {
  .nav-space {
    justify-content: center;
  }
}
.copyright-link,
.copyright-link:hover {
  color: rgb(82, 189, 199) !important;
  text-decoration: none;
  font-weight: 500;
}

.thankyou-page-wrapper {
  height: 100%;
  min-height: calc(100vh - 142px);
}
.inner {
  padding: 150px 0 80px;
}
.home-cta {
  border-radius: 20px;
  padding: 60px;
}
.bg-safon {
  background-color: #52bdc7;
}
.home-cta img {
  border-radius: 20px;
  box-shadow: 0 10px 22px #00000029;
  margin-top: -134px;
}
.scroll-up a {
  position: fixed;
  bottom: 10%;
  right: 10px;
  background: #52bdc7;
  width: 2em;
  height: 2em;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  text-align: center;
  line-height: 1.9;
  color: #1c1c1c;
  z-index: 99;
  right: 0;
}
.scroll-up a i {
  font-size: 1.2em;
}
.grecaptcha-badge {
  visibility: hidden !important;
}

@media (max-width: 991px) {
  .software-sec .bg-overlay {
    background-color: #f5f5f5;
    width: 100%;
    left: 0;
  }
  .cust-padding-2 {
    padding: 80px 0;
  }
  .testtimonial_content{
    min-height: auto !important;
  }
}
.form-label {
  font-weight: 500;
}
