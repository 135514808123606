:root {
  --theme-heading-font: "Noto Sans", sans-serif;
  --theme-secondary-font: "Noto Sans", sans-serif;
  --theme-primary-color: #f14d5d;
  --theme-secondary-color: #015abd;
  --theme-heading-color: #000;
  --theme-text-color: #77838f;
  --theme-white-color: #fff;
  --theme-border-color: #ddd;
}
html,
body {
  font-family: "Noto Sans", sans-serif !important;
}
.job-desc li,
.list-styled,
.propel-item ul li {
  list-style: disc;
}
.App,
.case-box,
.default-btn,
.num,
.ribbon,
.wb-design-longe .each-slide-effect {
  text-align: center;
}
.masthead,
.masthead.sunbelt,
.masthead.tracky {
  /* background-color: #0c5b79;
  -webkit-clip-path: circle(1420px at 50% calc(100% - 1430px)) !important;
  clip-path: circle(1420px at 50% calc(100% - 1430px)); */
  background-image: url(./app//assets//images/banner/case-studies-bg.png);
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position-y: bottom;
  background-position-x: center;
}
.hidden-4,
.progress,
.ribbon-content,
.scrolling-words-box,
.wb-design-longe {
  overflow: hidden;
}
.author-image img,
.blog-banner img,
.team-image img,
.tracky-mockup {
  object-fit: cover;
}
.case-box,
.case-box-2 {
  align-content: center;
}
div#root {
  overflow-x: clip !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.cust-btn-primary,
.cust-btn-secondary {
  padding: 18px 26px;
  font-weight: 700;
  height: auto;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -0.1px;
  width: auto;
  line-height: 1;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}
.footer-social li a,
.list-inline-item {
  display: inline-block;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 1400px) {
  .custom-cn-width {
    max-width: 1320px;
  }
}
.hidden-4,
.item img {
  max-width: 100%;
}
.cust-btn-primary {
  background: #ffbf00;
  color: #000;
  font-size: 15px;
  border-radius: 6px;
  border: 0;
}
.cust-btn-primary.light-variant,
.menu_fixed .cust-btn-primary,
.secondary-main {
  background: #0c5b79;
  color: #fff;
}
.cust-btn-primary.light-variant:hover,
.menu_fixed .cust-btn-primary:hover,
.secondary-main:hover {
  background: #0c3b48;
  color: #fff;
}
.cust-btn-secondary {
  background: #f9c755;
  color: #fff;
  font-size: 15px;
  border-radius: 6px;
  border: 0;
}
.pdt-12 {
  padding-top: 12px !important;
}
.banner-video {
  position: absolute;
  top: 0px;
  height: calc(100% + 100px);
  width: 100%;
  /* background-image: url(./app/assets/images/banner/banner.png); */
}

.about-border,
.sl-wrapper,
footer {
  position: relative;
}
.banner-video img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-top: -102px;
  object-position: left;
}
button.btn-prev-slider.nav {
  background: transparent;
  font-size: 24px;
  margin-left: -20px;
}
button.btn-next-slider.nav {
  background: transparent;
  font-size: 24px;
  margin-right: -20px;
  padding-right: 0;
}
.counter-mtext {
  font-size: 13px;
  font-weight: 400;
}
.header-navbar.custom-dark-header li .menu-trigger,
.text-dark {
  color: #000 !important;
}
.header-navbar .primary-menu li .has-subnav-link::before {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  top: 56px;
  bottom: -2px;
  background: #fff;
  transition: width 0.2s linear;
}
.header-navbar .primary-menu li:hover a:before {
  width: 75%;
}
.header-navbar .primary-menu li .submenu li a::before {
  display: none !important;
}
.gen-banner-wrapper {
  background-image: url("../src/app/assets/images/banner/banner.jpg");
  background-size: cover;
  border-radius: 10px;
  padding: 1.4em 2em 2em;
  background-position: right;
}
.legend,
legend {
  padding: 0 10px;
  float: none;
  font-size: 16px;
  color: #bfbfbf;
  text-transform: uppercase;
}
.gen-banner-wrapper .border-line {
  color: #fff !important;
  border-color: #fff !important;
}
.case-box-details h6,
.case-box-details p,
.custom-light-header.mobile-menu a.nav-toggler,
.gen-banner-wrapper .underline-border i,
.header-navbar .primary-menu.light li .menu-trigger,
.header-navbar .primary-menu.light li a,
footer a:hover,
footer h4,
footer h5 {
  color: #fff;
}
.solutions-child .card {
  padding: 1rem 2em 2em;
  border-radius: 10px;
}
.legend {
  width: fit-content;
  font-weight: 400;
  margin-top: -28px;
  background: #fff;
  margin-bottom: 10px;
}
.transition,
a {
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.for-a-hover:hover a,
.for-a-hover:hover span,
.gen-banner-wrapper:hover a,
.gen-banner-wrapper:hover span {
  border-color: #bfbfbf !important;
  color: #bfbfbf !important;
}
.for-a-hover:hover i,
.gen-banner-wrapper:hover i {
  margin-left: 5px;
  transition: margin 0.2s;
}
.border-line,
.for-a-hover .underline-border,
.gen-banner-wrapper .underline-border {
  border-color: #ff6701;
  color: #ff6701;
}
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 700 !important;
}
nav.site-navbar {
  display: flex;
  align-items: baseline;
}
.cust-btn-primary:hover {
  background: #e8c928;
  color: #0f4b5b;
}
.acc-light .num,
.global-sec {
  background-color: #f5f5f5;
}
.me-20 {
  margin-right: 20px;
}
.sl-wrapper .card {
  padding: 30px;
  border-radius: 20px;
  height: 100%;
  margin-top: 44px;
}
.testi-img img {
  margin-top: -90px;
  position: absolute;
  top: 16px;
}
.border-inner {
  box-shadow: 0 8px 24px rgb(0 0 0 / 13%);
  border-radius: 20px !important;
  background: #fff;
}
.timeline__event__content,
.timeline__event__icon {
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}
.feature-box .icon {
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
}
.feature-box .icon-1 {
  background: #9341d4;
  background: linear-gradient(45deg, #9341d4 0, #d98efb 100%);
  filter: drop-shadow(0px 40px 15px rgba(147, 65, 212, 0.4));
  -webkit-filter: drop-shadow(0px 40px 15px rgba(147, 65, 212, 0.4));
  -moz-filter: drop-shadow(0px 40px 15px rgba(147, 65, 212, 0.4));
}
.feature-box .icon-2 {
  background: #ffdb8d;
  background: linear-gradient(45deg, #ffb16b 0, #ffdb8d 100%);
  filter: drop-shadow(0px 40px 15px rgba(255, 186, 114, 0.4));
  -webkit-filter: drop-shadow(0px 40px 15px rgba(255, 186, 114, 0.4));
  -moz-filter: drop-shadow(0px 40px 15px rgba(255, 186, 114, 0.4));
}
.feature-box .icon-3 {
  background: #63e8e4;
  background: linear-gradient(45deg, #19a5a1 0, #63e8e4 100%);
  filter: drop-shadow(0px 40px 15px rgba(79, 188, 186, 0.4));
  -webkit-filter: drop-shadow(0px 40px 15px rgba(79, 188, 186, 0.4));
  -moz-filter: drop-shadow(0px 40px 15px rgba(79, 188, 186, 0.4));
}
.timeline__event:nth-child(odd) .timeline__event__icon:before,
.timeline__event__icon:before {
  background: #c1c1c1;
  width: 2px;
  transform: translateX(-50%);
  position: absolute;
  z-index: -1;
  left: 50%;
}
.line-box .col-md-6,
.line-box-3 .mls-content,
.line-box-4 .col-lg-3,
.line-box-6 .col-lg-2 {
  padding: 30px 20px;
  border: 1px solid #ddd;
  margin-left: -1px;
  margin-bottom: -1px;
}
.line-box-3 .col-lg-2:nth-child(2n-1),
.line-box-3 .mls-content:nth-child(3n-1),
.line-box-4 .col-lg-3:nth-child(2n-1) {
  border-left: 0;
}
.line-box-3 .col-lg-2:nth-child(-n + 5),
.line-box-3 .mls-content:nth-child(-n + 3),
.line-box-4 .col-lg-3:nth-child(-n + 4) {
  border-top: 0;
}
.line-box-3 .col-lg-2:nth-child(-n + 30),
.line-box-3 .mls-content:nth-child(-n + 6),
.line-box-4 .col-lg-3:nth-child(-n + 12) {
  border-bottom: 0;
  border-left: 0;
}
.line-box-3 .col-lg-2:nth-child(5n-0),
.line-box-3 .mls-content:nth-child(3n-0) {
  border-right: 0;
  border-bottom: 0;
}
.text-grad {
  color: #0c5b79;
  font-weight: 300;
  font-family: Courgette, cursive !important;
  padding: 0 3px;
}
.line-box-3 .col-lg-2 {
  padding: 25px;
  border: 1px solid #ddd;
  margin-left: -1px;
  margin-bottom: -1px;
  width: 20%;
}
.home-cta {
  background: #f5f5f5;
  padding: 60px;
  border-radius: 20px;
}
.home-cta img {
  margin-top: -134px;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0 10px 22px;
}
.mt-80 {
  margin-top: 80px;
}
.bg-dark {
  background-color: #0c3b48 !important;
}
.sec-title h2 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 300;
}
footer ul li {
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 15px;
  text-decoration: none;
}
footer a,
footer p {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}

footer p.f-14 {
  font-size: 14px !important;
}

footer ul li + li {
  margin-top: 8px;
}
footer {
  padding: 60px 0 30px;
}
.icon svg {
  fill: #efefef;
}
.case-box:hover a,
.text-yellow {
  color: #ffbf00;
}
.bg-warning {
  background-color: #ffbf00 !important;
}
.sl-wrapper {
  height: 90%;
}
.sl-wrapper .card h5 {
  margin-top: 40px !important;
}
.course-details-header .review-stars-rated i {
  color: #ffa41b;
  font-size: 18px;
}
.banner-content h2 {
  font-size: 46px;
  line-height: 1.3;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-16,
.main-btn {
  font-size: 16px !important;
}
.font-size-17 {
  font-size: 17px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-19 {
  font-size: 19px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-21 {
  font-size: 21px !important;
}
.font-size-22 {
  font-size: 22px !important;
}
.font-size-23 {
  font-size: 23px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
.font-size-25 {
  font-size: 25px !important;
}
.font-size-26 {
  font-size: 26px !important;
}
.font-size-27 {
  font-size: 27px !important;
}
.font-size-28 {
  font-size: 28px !important;
}
.font-size-29 {
  font-size: 29px !important;
}
.font-size-30 {
  font-size: 30px !important;
}
.font-size-31 {
  font-size: 31px !important;
}
.font-size-32 {
  font-size: 32px !important;
}
.font-size-34 {
  font-size: 34px !important;
}
.mobile-menu {
  padding: 18px 0;
}
@media (max-width: 1223px) {
  .pdt-12.cp-min-pd {
    padding-top: 0 !important;
  }
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.footer-social li {
  margin: 0;
}
.footer-social li a {
  width: 20px;
  height: 34px;
  line-height: 34px;
  border-radius: 60px;
  text-align: center;
  font-size: 16px;
}
.share-btn li a,
button.cs-btn svg {
  height: 34px;
  width: 34px;
}
.prod-sec .zoom:hover {
  box-shadow: 0 15px 30px rgb(0 0 0 / 8%);
}
.zoom img {
  -webkit-transition: -webkit-transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
  transition: transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
  transition: transform 2s cubic-bezier(0.2, 0.96, 0.34, 1),
    -webkit-transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
}
.zoom:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.inner {
  padding: 120px 0 80px;
}
.about-border img,
.border-20 {
  border: 15px solid #f6f6f6;
}
.about-border {
  padding: 0 0 10% 12%;
  z-index: 1;
}
.about-bg {
  height: 65%;
  width: 75%;
  z-index: -1;
  display: block;
  left: 15px;
  bottom: 0;
  position: absolute;
  background: #00e0ce;
  background: linear-gradient(to right, #0f4b5b 30%, #186a80 70%);
}
.timeline,
.timeline__event,
.timeline__event__icon {
  display: flex;
  position: relative;
}
.banner-sec p {
  color: #000;
  font-weight: 300;
}
.timeline {
  flex-direction: column;
}
.timeline__event:nth-child(odd) {
  flex-direction: row-reverse;
}
.timeline__event {
  margin-bottom: 28px;
  border-radius: 6px;
  align-self: center;
  z-index: 0;
}
.timeline__event__content {
  padding: 30px;
  background: #fff;
  width: calc(40vw - 84px);
  border-radius: 8px;
}
@media screen and (min-width: 1280px) and (max-width: 1400px) {
  .timeline__event__content {
    width: calc(44vw - 84px);
  }
}
.timeline__event__icon {
  align-items: center;
  justify-content: center;
  color: #9251ac;
  padding: 24px;
  align-self: center;
  margin: 0 30px;
  border-radius: 100%;
}
.timeline__event__icon:before {
  content: "";
  height: 100%;
  top: 100%;
}
.timeline__event:nth-child(odd) .timeline__event__icon:before {
  content: "";
  height: 100%;
  top: 100%;
  right: auto;
  animation: 2s ease-in-out 4s forwards fillTop;
}
.timeline__event:nth-child(odd) .timeline__event__icon:after,
.timeline__event__icon:after {
  content: "";
  background: #c1c1c1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  z-index: -1;
}
.timeline__event__icon:after {
  left: 100%;
}
.timeline__event:nth-child(odd) .timeline__event__icon:after {
  right: 100%;
  left: auto;
}
.bg-green1:before,
.down-sec.bg-light:before {
  top: 0;
  width: 75vh;
  position: absolute;
  height: 100%;
  content: "";
}
@media (max-width: 767px) {
  .bg-green1 .banner-sec {
    margin-bottom: 20px;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .down-sec > div {
    padding: 30px !important;
  }
  .kpo-sec {
    text-align: center;
    padding: 180px 10px 60px !important;
    background-image: url(../src/app/assets/images/kpo-media/kpo-mobile.jpg) !important;
    background-position: left !important;
  }
  .timeline__event:nth-child(odd) {
    flex-direction: column;
    align-self: center;
  }
  .title {
    font-size: 34px !important;
    margin-bottom: 15px;
  }
  .timeline__event__icon {
    border-radius: 6px;
    margin: 0 0 20px;
    padding: 15px;
  }
  .timeline__event__content {
    width: 100%;
    padding: 0 10px;
    background: transparent;
    box-shadow: none !important;
  }
}
.accordion-button::after,
.careers-page .accordion:before,
.custom-light-header.menu_fixed .lg-display-light,
.dark-res,
.hero-b2b-2,
.menu_fixed .light-res,
.timeline__event__icon.gradient-green.scope-none::before {
  display: none;
}
.cta-blue {
  background-color: #a9dcef;
}
.cta-block {
  background: #0c5b79;
}
fieldset {
  border: 1px solid #ddd;
  padding: 1em 2em 2em;
  border-radius: 10px;
  display: flex;
  height: 100%;
}
legend {
  width: auto;
  font-weight: 400;
}
.border-line {
  webkit-transition: all 0.4s ease-in-out;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #0c5b79;
  color: #0c5b79 !important;
  webkit-transition: all 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.other-sec fieldset a,
.other-sec fieldset a i {
  color: #0c5b79;
}
.underline-border i {
  font-size: 15px;
  top: 2px;
  transition: 0.3s ease-out;
  color: #0c5b79;
}
.other-sec fieldset:hover a,
.other-sec fieldset:hover a i {
  color: #bfbfbf;
  -webkit-background-clip: inherit;
  -webkit-text-fill-color: inherit;
  -webkit-box-decoration-break: inherit;
  box-decoration-break: none;
  background: transparent;
}
fieldset:hover .border-line {
  border-color: #bfbfbf;
  color: #bfbfbf;
}
.underline-border:hover i,
fieldset:hover a i {
  margin-left: 5px;
}
.team-overlay {
  position: absolute;
  height: 100%;
  background-color: #efefef;
  width: 100%;
  left: 50%;
  top: -25%;
}
.contact-info,
.default-btn,
.propel-item,
.team-desc,
header {
  position: relative;
}
header {
  z-index: 99;
}
.team-desc-border,
.team-image {
  height: auto;
  min-height: 18pc;
  border: 4px solid #c1c1c1;
  padding: 20px;
  overflow: hidden;
}
.team-desc {
  background-color: #fff;
  padding: 20px 18px;
  margin-top: -15%;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 1px 1px 15px #e1e1e1;
  box-shadow: 0 15px 30px rgb(0 0 0 / 8%);
}
.team-block:hover .team-image img {
  transform: scale(1.07);
  transition: 0.4s ease-in-out;
}
.pressrelease-page fieldset {
  border: 2px solid #9d9d9d !important;
}
.openings-sec .accordion-item {
  padding: 1.8rem !important;
  box-shadow: 0 10px 20px rgb(0 0 0 / 7%);
  border: 0;
}
.openings-sec .accordion-button {
  display: block;
  padding: 0;
}
.openings-sec .accordion-item + .accordion-item {
  margin-top: 30px;
}
.openings-sec .accordion-body {
  padding: 0;
}
.job-desc li {
  font-size: 17px;
  color: #000;
}
ul.contact-info,
ul.job-desc {
  padding-left: 2rem;
}
.openings-sec .job-desc li {
  line-height: 32px;
}
.banner-sec p.para,
p,
ul li {
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: normal;
  margin-bottom: 0;
}
.case-box a,
.pricing li,
p {
  font-size: 17px;
}
.accordion-button:not(.collapsed) {
  background-color: unset;
  border: 0;
  box-shadow: unset;
}
.bg-img {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.case-study-bg {
  background-color: #0c5b79;
  padding-top: 150px !important;
}
.case-box {
  min-height: 250px;
  padding: 15px;
  background-color: #173947;
  border: 1px solid #969696;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
}
.case-box .icon img {
  width: 40%;
}
.case-box a {
  color: #fff;
}
.case-box:hover {
  background-color: #14303c;
}
.bg-green1 {
  background: #52bdc7;
  padding: 80px 30px 80px 0;
}
.bg-green1:before {
  background: #52bdc7;
  left: 0;
}
.cta-block,
.down-sec > div {
  padding: 80px;
}
.bg-light {
  background-color: #f5f5f5 !important;
}
.down-sec.bg-light:before {
  background: #f7f7f7;
  right: 0;
}
.form-control {
  border: 2px solid #ced4da !important;
  border-radius: 4px;
}
.contact-bg {
  background-image: url(../src/app/assets/images/map.png);
}
.contact-info li {
  list-style: none;
  padding-left: 0;
  line-height: 1.7;
  margin-bottom: 5px;
}
.contact-info i,
.contact-info svg {
  position: absolute;
  left: 5px;
  margin-top: 6px !important;
  color: #0c5b79;
}
.header-navbar .primary-menu.light .submenu li a,
.header-navbar .primary-menu.light li:hover a,
.header-navbar .primary-menu.light li:hover i.submenu-icon,
.header-navbar.custom-dark-header .primary-menu li a,
.menu_fixed ul.primary-menu.light li .menu-trigger,
.menu_fixed ul.primary-menu.light li a,
.navbar-sticky.custom-light-header.mobile-menu.menu_fixed a.nav-toggler,
ul.contact-info li {
  color: #000;
}
ul.contact-info li a {
  color: #0c5b79;
  text-decoration: none;
  font-size: 17px;
}
.contact-img {
  background-image: url(../src/app/assets/images/contact.jpg);
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 0 10px 10px 0;
}
.gen-home {
  background-image: url(../src/app/assets/images/genai-media/banner.jpg);
  padding: 220px 0 80px;
}
.itsuport-home {
  background-image: url(../src/app/assets/images/itsupport/banner.jpg);
  padding: 220px 0 80px;
}
.gen-home.banner-sec h2:after {
  content: "";
  display: block;
  width: 70px;
  height: 2px;
  background-color: #fff;
  margin-top: 5px;
}
.propel-item {
  box-shadow: 0 25px 100px 0 rgba(0, 0, 0, 0.1490196078);
  z-index: 2;
  padding: 2rem;
}
.ai-service-sec {
  background-color: #efefef;
}
.icons {
  width: 50px;
  height: 50px;
  font-size: 20px;
}
.gradient-purple {
  background-image: -moz-linear-gradient(
    90deg,
    #6a2fff 0,
    #8c3dff 53%,
    #ae4aff 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #6a2fff 0,
    #8c3dff 53%,
    #ae4aff 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    #6a2fff 0,
    #8c3dff 53%,
    #ae4aff 100%
  );
  background-image: linear-gradient(
    90deg,
    #6a2fff 0,
    #8c3dff 53%,
    #ae4aff 100%
  );
  -webkit-box-shadow: 0 20px 16px 0 rgb(106 47 255 / 16%);
  -moz-box-shadow: 0 20px 16px 0 rgba(106, 47, 255, 0.16);
  box-shadow: 0 20px 16px 0 rgb(106 47 255 / 16%);
}
.gradient-yellow {
  background-image: -moz-linear-gradient(
    90deg,
    #e69303 0,
    #f3b704 53%,
    #ffda04 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #e69303 0,
    #f3b704 53%,
    #ffda04 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    #e69303 0,
    #f3b704 53%,
    #ffda04 100%
  );
  background-image: linear-gradient(
    90deg,
    #e69303 0,
    #f3b704 53%,
    #ffda04 100%
  );
  -webkit-box-shadow: 0 20px 16px 0 rgb(246 157 3 / 16%);
  -moz-box-shadow: 0 20px 16px 0 rgba(246, 157, 3, 0.16);
  box-shadow: 0 20px 16px 0 rgb(246 157 3 / 16%);
}
.gradient-blue {
  background-image: -moz-linear-gradient(
    90deg,
    #023cfd 0,
    #0372fe 53%,
    #03a7ff 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #023cfd 0,
    #0372fe 53%,
    #03a7ff 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    #023cfd 0,
    #0372fe 53%,
    #03a7ff 100%
  );
  background-image: linear-gradient(
    90deg,
    #023cfd 0,
    #0372fe 53%,
    #03a7ff 100%
  );
  -webkit-box-shadow: 0 20px 16px 0 rgb(2 60 253 / 16%);
  -moz-box-shadow: 0 20px 16px 0 rgba(2, 60, 253, 0.16);
  box-shadow: 0 20px 16px 0 rgb(2 60 253 / 16%);
}
.gradient-green {
  background-image: -moz-linear-gradient(
    90deg,
    #458e4e 0,
    #24af67 53%,
    #0cd68a 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #458e4e 0,
    #24af67 53%,
    #0cd68a 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    #458e4e 0,
    #24af67 53%,
    #0cd68a 100%
  );
  background-image: linear-gradient(
    90deg,
    #458e4e 0,
    #24af67 53%,
    #0cd68a 100%
  );
  -webkit-box-shadow: 0 20px 16px 0 rgb(246 157 3 / 16%);
  -moz-box-shadow: 0 20px 16px 0 rgba(246, 157, 3, 0.16);
  box-shadow: 0 20px 16px 0 rgb(15 206 131 / 16%);
}
.gradient-pink {
  background-image: -moz-linear-gradient(
    90deg,
    #fe0c7d 0,
    #ff58a8 53%,
    #ffa4d2 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #fe0c7d 0,
    #ff58a8 53%,
    #ffa4d2 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    #fe0c7d 0,
    #ff58a8 53%,
    #ffa4d2 100%
  );
  background-image: linear-gradient(
    90deg,
    #fe0c7d 0,
    #ff58a8 53%,
    #ffa4d2 100%
  );
  -webkit-box-shadow: 0 20px 16px 0 rgb(254 12 125 / 16%);
  -moz-box-shadow: 0 20px 16px 0 rgba(254, 12, 125, 0.16);
  box-shadow: 0 20px 16px 0 rgb(254 12 125 / 16%);
}
.fill-white {
  fill: #fff;
}
.author-image img {
  width: 100px;
  height: 100px;
}
.team-image img {
  width: 285px;
}
.default-btn {
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  font-weight: 700;
  color: #000;
  padding: 18px 26px;
  display: inline-block;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none !important;
  line-height: 1;
  border-radius: 6px;
  background: #ffbf00;
}
.black-white-image {
  filter: grayscale(100%);
}
.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}
.ivideo-sec {
  padding: 180px 0 100px;
  background: #efefef;
}
.design-block {
  width: 100%;
  background: #fff;
  padding: 54px;
  box-shadow: 0 3px 20px rgb(0 0 0 / 8%);
  margin-left: -140px;
}
.features-item {
  background-color: #fff;
  box-shadow: 0 20px 50px 0 rgb(0 0 0 / 8%);
  padding: 36px;
  border-radius: 6px;
  transition: 0.3s ease-out;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bg-grey-pricing {
  background-color: #f9f9f996 !important;
}
.ribbon-content {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 180px;
  height: 180px;
}
.ribbon {
  display: inline-block;
  position: absolute;
  padding: 7px 0;
  left: 13px;
  top: 48px;
  width: 206px;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  text-shadow: 0 2px 2px #56a1f3;
  letter-spacing: 0.05em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.height-50,
.num {
  position: relative;
}
.ivideo-cta {
  background: #f0f0f0;
}
.brand-slider img {
  width: 250px;
}
button.cs-btn {
  background: unset;
  color: #000;
}
button.cs-btn.btn-left.nav {
  margin-left: -50px;
}
button.cs-btn.btn-right.nav {
  margin-right: -70px;
}
.react-slideshow-wrapper .images-wrap.horizontal {
  align-items: center;
}
.b-r-6 {
  border-radius: 10px;
}
.default-btn:hover {
  background-color: #f7c757;
  color: #0f4b5b;
}
.fill-success {
  color: #198754;
}
.svg-fs {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  flex-shrink: 0;
}
.margin-minus {
  margin-top: -101px;
}
.client-sec {
  padding: 240px 0 200px;
  background-image: url(../src/app/assets/images/client-success/banner.jpg);
  background-size: cover;
}
.cta-violet {
  background-color: #afccff;
}
.w-35 {
  width: 35% !important;
}
.sss-sec {
  padding: 240px 0 150px;
  background-image: url(../src/app/assets/images/staff-solutions/banner.jpg);
  background-position: center;
}
.gradient-orange {
  background-image: -moz-linear-gradient(
    90deg,
    #ff5919 0,
    #ff753e 53%,
    #ff723a 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #ff5919 0,
    #ff753e 53%,
    #ff723a 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    #ff5919 0,
    #ff753e 53%,
    #ff723a 100%
  );
  background-image: linear-gradient(
    90deg,
    #ff5919 0,
    #ff753e 53%,
    #ff723a 100%
  );
  -webkit-box-shadow: 0 20px 16px 0 rgb(246 157 3 / 16%);
  -moz-box-shadow: 0 20px 16px 0 rgba(246, 157, 3, 0.16);
  box-shadow: 0 20px 16px 0 rgb(255 87 34 / 20%);
}
.right .about-bg {
  right: 15px;
  top: 0;
  bottom: auto;
  left: auto;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#423f9c),
    to(#85245a)
  );
  background-image: -o-linear-gradient(left, #423f9c, #85245a);
  background-image: linear-gradient(90deg, #423f9c, #85245a);
}
.num {
  width: 52px;
  height: 52px;
  background-color: rgb(247 248 250);
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  padding: 3px;
  z-index: 1;
  font-size: 28px;
  line-height: 42px;
  margin-right: 15px;
  font-weight: 600;
}
.accordion:before {
  content: "";
  position: absolute;
  left: 25px;
  height: 100%;
  width: 3px;
  background: #bcc8ce;
}
.accordion-button {
  padding: 0;
  border: 0 !important;
  background: transparent !important;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #000 !important;
  z-index: 1 !important;
  text-align: left;
}
.accordion-item + .accordion-item {
  padding-top: 20px;
}
.accordion-item {
  border: 0;
}
.about-border.right {
  padding: 10% 12% 0 0;
}
.marketing-sec:before {
  content: "";
  width: 62%;
  height: 100%;
  position: absolute;
  background: #f7f7f7;
  top: 0;
  left: 0;
}
@media screen and (min-width: 1280px) and (max-width: 1400px) {
  .marketing-sec:before {
    width: 70%;
  }
}
.marketing-sec {
  padding: 140px 0 80px;
}
.banner-sec h2 {
  font-size: 46px;
  line-height: 1.3;
  font-weight: 400;
}
.cp-height {
  height: 444px;
}
.violet-bg {
  background: #c7b9ff;
}
.web-sec:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #efefef;
  left: 0;
}
.bg-green:before,
.bg-yellow:before {
  position: absolute;
  width: 101vh;
  right: -100vh;
  content: "";
}
.bg-yellow {
  background-color: #d6bb32;
  background-image: url(../src/app/assets/images/webdesign-media/bg1.jpg);
  background-position: 15% 40%;
  background-size: cover;
}
.bg-green:before {
  height: 50vh;
  background: #9cc11b;
  z-index: -1;
}
.bg-yellow:before {
  height: 100%;
  background: #d4b83d;
  z-index: -2;
}
.height-50 {
  height: 50vh;
  z-index: 1;
}
.bg-green {
  background-color: #9cc11a;
}
.w-450 {
  width: 450px;
}
.img-right {
  position: absolute;
  right: -10px;
  bottom: -5%;
  left: auto;
  width: 50%;
}
.h-75 {
  height: 75% !important;
}
.wb-design-longe ul.indicators {
  scale: 1.5;
}
.wb-design-longe
  .react-slideshow-container
  + ul.indicators
  .each-slideshow-indicator:before {
  background: #0a4b5c;
}
.counter {
  font-size: 4rem;
  line-height: 0.8;
  font-weight: 700;
}
.f-18 {
  font-size: 18px;
}
.custom-outline {
  margin: 0;
  border: 1px solid #000;
  text-align: center;
  padding: 5px 7px;
  line-height: 28px;
}
.custom-outline svg {
  width: 20px !important;
  height: 24px !important;
}
.software-sec .bg-overlay {
  background-color: #f5f5f5;
  width: 70%;
  left: 0;
}
.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.teal-bg {
  background: #26d5d9;
}
.cta-light-green {
  background-color: #cff7b8;
}
@media screen and (max-width: 1200px) {
  .callcenter-sec:before,
  .careers-sec:before,
  .marketing-sec:before,
  .software-sec .bg-overlay {
    width: 100%;
  }
  .design-block {
    margin: auto;
  }
}
.kpo-sec {
  padding: 220px 0 120px;
  background-image: url(../src/app/assets/images/kpo-media/kpo-bg.jpg);
  background-position: 70% 15%;
}
.banner-sec p {
  font-size: 1.1em;
  line-height: 1.6em;
}
.pink-bg {
  background: #ffafcc;
}
@media screen and (max-width: 1700px) {
  .callcenter-sec:before {
    width: 65%;
    left: 5%;
  }
}
@media screen and (max-width: 1500px) {
  .callcenter-sec:before {
    width: 70%;
    left: 0;
  }
  .case-study-sec h1 {
    font-size: 64px;
    line-height: 74px;
  }
}
.callcenter-sec:before {
  content: "";
  width: 60%;
  height: 100%;
  position: absolute;
  background: #00bcd4;
  top: 0;
  left: 10%;
}
.nsc-client-img {
  background-image: url(../src/app/assets/images/ncco-media/nearshore-bg.jpg);
}
.sdr-banner {
  padding-top: 27px;
  padding-bottom: 100px;
  height: 100%;
  min-height: 799px;
}
.sdr-banner .hero-image {
  position: absolute;
  z-index: -1;
  left: 0;
  top: -4%;
}
.sdr-banner .shape-1 {
  margin-right: -30px;
  margin-bottom: -15px;
}
.sdr-banner .banner-text {
  font-family: "Frank Ruhl Libre", serif !important;
  font-weight: 400;
  font-size: 170px;
  line-height: 80%;
  letter-spacing: -5px;
}
.ssbp-facebook::before,
.ssbp-linkedin::before,
.ssbp-twitter::before {
  font-family: "Font Awesome 5 Brands" !important;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.sdr-banner .top-sec {
  position: absolute;
  top: 0;
  right: 5%;
  width: 300px;
}
.sdr-banner .bottom-sec {
  position: absolute;
  bottom: -61px;
  right: 0;
  width: 300px;
}
.sdr-banner .shape-2 {
  position: absolute;
  right: 20%;
  bottom: 0;
}
.progress,
.progress .title {
  position: relative;
  margin: auto;
}
.mouse-scroll-animation .scroll-animation .mouse {
  height: 40px;
  width: 27px;
  border-radius: 30px;
  border: 2px solid #000;
  margin-bottom: 3px;
}
.mouse-scroll-animation .scroll-animation .mouse .wheel {
  animation: 1.5s infinite mouse-wheel;
  height: 10px;
  width: 3px;
  background: #000;
  margin: 3px auto;
  border-radius: 3px;
}
@keyframes mouse-wheel {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(18px);
  }
}
.process-sec .sb-block {
  min-height: 544px;
}
.text-muted {
  color: #6c757d !important;
}
.progress {
  width: 200px;
  height: 200px;
  font-size: 30px;
  color: #000;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  line-height: 200px;
  scale: 1.7;
}
.progress .title {
  z-index: 100;
}
.progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fff0;
}
.progress .left,
.progress .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 7px solid #0c3b48;
  border-radius: 100px 0 0 100px;
  border-right: 0;
  transform-origin: right;
}
.progress .left {
  animation: 1s linear forwards load1;
}
.progress:nth-of-type(2) .right,
.progress:nth-of-type(3) .right {
  animation: 0.5s linear 1s forwards load2;
}
.progress:first-of-type .right,
.progress:last-of-type .right {
  animation: 0.8s linear 1s forwards load3;
}
@keyframes load1 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes load2 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }
  100% {
    z-index: 100;
    transform: rotate(270deg);
  }
}
@keyframes load3 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }
  100% {
    z-index: 100;
    transform: rotate(315deg);
  }
}
.title.timer h1 {
  font-size: 60px;
  margin-bottom: 0;
}
.title.timer p {
  font-size: 12px;
}
span.outer-circle {
  width: 99%;
  height: 195px;
  border: 2px solid #c7c7c7;
  border-radius: 500px;
  position: absolute;
  left: 1px;
  top: 3px;
  z-index: 0;
}
.f-em {
  font-family: Merriweather, serif !important;
  font-style: italic;
}
.sec-title {
  font-weight: 500;
  font-size: 64px;
  line-height: 70px;
}
.boost-sec p {
  font-family: "DM Sans", sans-serif;
  color: #4d4d4d;
  font-size: 20px;
}
.my-b {
  margin-top: -40px;
  margin-bottom: 50px;
}
.leader-sec .sec-title {
  font-weight: 700;
}
.cta-sec.sec-2 {
  padding: 100px 0 140px;
  background-color: #0c3b48 !important;
}
.ssbp--theme-1 .ssbp-ellipsis,
.ssbp--theme-1 .ssbp-ellipsis + .ssbp-each-share {
  background-color: #4582ec;
}
.cta-sec.sec-2 .sec-title {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 88px;
  line-height: 97px;
}
.cta-sec.sec-2 .arrow-right {
  position: absolute;
  right: 0;
  top: 60%;
}
.cs-title {
  font-size: 54px;
}
.hidden-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.pressrelease-detail a {
  color: #1976d2;
}
.bx-wrapper {
  -moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  box-shadow: 0 0 5px #ccc;
  border: 5px solid #fff;
  background: #fff;
}
.bx-wrapper.each-slide-effect {
  position: relative;
  margin: 0 auto 60px;
  padding: 0;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
button.cs-btn.btn-sc-left.nav svg,
button.cs-btn.btn-sc-right.nav svg {
  width: 24px;
  height: 24px;
  fill: #b2b1b0;
}
button.cs-btn.btn-sc-left.nav,
button.cs-btn.btn-sc-right.nav {
  background: #5d646f;
  padding: 5px;
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 20px;
}
.share-btn li a {
  text-align: center;
  color: #fff !important;
}
.share-btn li:nth-child(2) a {
  background-color: #365899;
}
.share-btn li:nth-child(2) a:hover {
  background-color: #2d4b86;
}
.share-btn li:nth-child(3) a {
  background-color: #4c99ea;
}
.share-btn li:nth-child(3) a:hover {
  background-color: #3b7abd;
}
.share-btn li:nth-child(4) a {
  background-color: #000000;
}
.share-btn li:nth-child(4) a:hover {
  background-color: #000000;
}
#ssba-bar-2.right .ssbp-bar-list {
  max-width: 84px;
  text-align: right;
  min-width: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}
#ssba-bar-2 .ssbp-bar-list li {
  margin: 0;
  display: block;
  position: relative;
  margin-bottom: 6px !important;
}
#ssba-bar-2 ul li a {
  max-width: 84px;
  display: block;
}
.square-element .info .image img,
.ssba-classic-2,
.ssba-plus-2 {
  width: 100%;
}
#ssba-bar-2 li .ssbp-each-share,
.hero-b2b-1,
.menu_fixed .dark-res,
.ssbp-each-share {
  display: block;
}
.right li .ssbp-each-share {
  left: unset;
  right: 100%;
  margin-right: -12px;
  margin-left: 0;
}
#ssba-bar-2.left .ssbp-bar-list {
  max-width: 84px;
  text-align: left;
  min-width: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}
#ssba-bar-2.left {
  position: fixed;
  top: 25vh;
  z-index: 10000;
}
.ssbp--theme-1 .ssbp-ellipsis:active,
.ssbp--theme-1 .ssbp-ellipsis:focus,
.ssbp--theme-1 .ssbp-ellipsis:hover {
  color: #4582ec;
  border-color: #4582ec;
}
.ssbp--theme-1 .ssbp-facebook,
.ssbp--theme-1 .ssbp-facebook + .ssbp-each-share,
.ssbp--theme-1 .ssbp-facebook_save,
.ssbp--theme-1 .ssbp-facebook_save + .ssbp-each-share {
  background-color: #3b5998;
}
.ssbp--theme-1 .ssbp-facebook:active,
.ssbp--theme-1 .ssbp-facebook:focus,
.ssbp--theme-1 .ssbp-facebook:hover,
.ssbp--theme-1 .ssbp-facebook_save:active,
.ssbp--theme-1 .ssbp-facebook_save:focus,
.ssbp--theme-1 .ssbp-facebook_save:hover {
  color: #3b5998;
  border-color: #3b5998;
}
.ssbp--theme-1 .ssbp-facebook-messenger,
.ssbp--theme-1 .ssbp-facebook-messenger + .ssbp-each-share {
  background-color: #0084ff;
}
.ssbp--theme-1 .ssbp-facebook-messenger:active,
.ssbp--theme-1 .ssbp-facebook-messenger:focus,
.ssbp--theme-1 .ssbp-facebook-messenger:hover {
  color: #0084ff;
  border-color: #0084ff;
}
.ssbp--theme-1 .ssbp-btn {
  width: 3em;
  height: 3em;
  line-height: 3em;
  position: relative;
  border-radius: 50%;
  color: #fff;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
.ssbp-wrap .ssbp-btn {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: sans-serif;
}
.ssbp--theme-1 .ssbp-linkedin {
  background-color: #007bb6;
}
.ssbp--theme-1 .ssbp-twitter {
  background-color: #000000;
}
.ssbp--theme-1 .ssbp-container {
  padding: 6px;
}
.ssbp--theme-1 .ssbp-facebook:hover {
  border: 1px solid #3b5998 !important;
  background-color: unset;
}
.ssbp--theme-1 .ssbp-linkedin:active,
.ssbp--theme-1 .ssbp-linkedin:focus,
.ssbp--theme-1 .ssbp-linkedin:hover {
  border: 1px solid #007bb6 !important;
  background-color: unset;
  color: #007bb6;
}
.ssbp--theme-1 .ssbp-twitter:active,
.ssbp--theme-1 .ssbp-twitter:focus,
.ssbp--theme-1 .ssbp-twitter:hover {
  border: 1px solid #000000 !important;
  background-color: unset;
  color: #000000;
}
.masthead.sunbelt,
.masthead.tracky {
  /* background-color: #0c5b79; */
}
@media (min-width: 992px) {
  .w-20 .col-lg-2 {
    width: 20%;
  }
  .footer-bottom .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }
  #apply-now .modal-dialog {
    max-width: 620px;
  }
  .propel-left {
    margin-left: -120px;
  }
  .propel-item {
    min-height: 245px;
  }
  .propel-right {
    margin-right: -120px;
  }
  .masthead.skoolers,
  .masthead.sunbelt,
  .masthead.tracky {
    padding-top: 5rem !important;
  }
  .process-sec .sb-block {
    min-height: 596px;
    height: 100%;
  }
}
@media (min-width: 1200px) {
  .fs-3 {
    font-size: 1.75rem !important;
  }
}
.fs-3 {
  font-size: calc(1.2rem + 0.6vw) !important;
}
.content1 img {
  width: 70px;
}
.case-skoolers {
  background-color: #7a97ff;
}
.case-tracky {
  background-color: #0c97fb;
}
.case-sunbelt {
  background-color: #633aff;
}
.case-practica {
  background-color: #143675;
}
.case {
  transition: transform 0.3s;
}
.case:hover {
  transform: translateY(-10px);
}
.cta {
  background: radial-gradient(rgba(0, 0, 0, 0.8) 1px, #080921 1px, #02011a 1px);
  background-size: 24px 24px;
  background-position-y: 6px;
}
.page-header.itsuport-home:after,
.video-player {
  background: transparent;
}
.video-player {
  position: relative;
  width: 100%;
  height: 500px;
  margin: 0 auto 50px;
}
.banner-video_wrapper {
  display: flex;
  padding: 0 170px 150px;
  justify-content: center;
}
.my-video {
  background-color: #000;
  height: auto !important;
  width: 820px !important;
  border-radius: 15px;
}
.my-video video {
  border: 20px solid #000;
  border-radius: 15px;
}
.text-bold {
  font-size: 22px;
  font-weight: 600;
}
.b-r-r-b {
  border-radius: 0 10px 10px 0;
}
.bg-orange {
  background: #ff6700;
}
.b-r-t-b {
  border-radius: 10px 0 0 10px;
}
.case-study-sec h1 {
  font-size: 54px;
  font-weight: 600;
  white-space: break-spaces;
  line-height: 90px;
}
.case-study-count h1 {
  font-size: 70px;
  line-height: 80px;
}
.scroll-navigator ul li a {
  color: #14133a;
}
.scrolling-words-container {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
}
.scrolling-words-box {
  height: 3rem;
  margin: auto;
}
.scrolling-words-box ul {
  margin: 0 0.625rem;
  padding: 0;
  animation: 4s infinite scrollUp;
}
.scrolling-words-box ul li {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3rem;
  list-style: none;
}
.m-left {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 50px;
  transform: translate(-82%, -50%);
  border-radius: 5px;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-box-shadow: 0 11px 54px 0 rgb(13 0 30 / 10%);
  box-shadow: 0 11px 54px 0 rgb(13 0 30 / 10%);
  background: #fff;
  z-index: 1;
}
@media screen and (max-width: 1500px) {
  .case-study-sec h1 {
    font-size: 64px;
    line-height: 74px;
  }
}
.header-navbar.custom-light-header.menu_fixed .primary-menu li a:hover,
.header-navbar.custom-light-header.menu_fixed .primary-menu li:hover {
  color: #0c5b79 !important;
}
.header-navbar.custom-dark-header .primary-menu li a::before,
.header-navbar.menu_fixed .primary-menu li .has-subnav-link::before {
  background: #000;
}
.custom-light-header.menu_fixed .lg-display-dark {
  display: block !important;
}
@media screen and (max-width: 767px) {
  button.cs-btn.btn-left.custom-outline.nav,
  button.cs-btn.btn-right.custom-outline.nav {
    display: none;
  }
  .banner-content h2,
  .banner-sec h1 {
    font-size: 1.7em;
    line-height: 1.4;
  }
  section.counter-section4 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .counter-section4 {
    margin-top: -150px;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .section-padding {
    padding: 40px 0;
  }
  .me-sm-0 {
    margin-right: 0;
  }
  .my-video {
    width: 100% !important;
  }
  .content1 {
    justify-content: center;
  }
  .banner-video_wrapper {
    padding: 0 20px 70px;
  }
}
@media screen and (max-width: 991px) {
  .cp-mt {
    margin-top: 0;
  }
  .boost-sec.leader-sec {
    text-align: center;
  }
  .callcenter-sec:before {
    width: 100% !important;
    left: 0 !important;
  }
  .masthead {
    padding-top: 60px;
  }
  .banner-sec.bg-dark.case-study-sec {
    padding-top: 120px;
    text-align: center;
  }
}
@media screen and (max-width: 1200px) {
  .default-btn,
  .f-16 {
    font-size: 15px;
  }
  .section-padding {
    padding: 40px 0;
  }
  body {
    line-height: 1.4;
  }
  .cta-block {
    padding: 50px;
  }
  .bg-1,
  .home-cta {
    padding: 30px;
  }
  p,
  ul li {
    font-size: 17px;
    line-height: 1.5em;
  }
  h1 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  .banner-sec h1,
  .banner-sec h2 {
    font-size: 34px;
  }
  .cta-sec .h2,
  .cta-sec h2,
  .sec-title .h2,
  .sec-title h2 {
    font-size: 28px;
    line-height: 1.2em;
  }
  .banner-sec p,
  .sec-title p {
    font-size: 17px;
    line-height: 26px;
  }
  .banner-sec h4 {
    font-size: 21px;
    line-height: 26px;
  }
  fieldset {
    padding: 0.95em 1.5em 1.5em;
    justify-content: center;
  }
  .mt-80 {
    margin-top: 40px;
  }
  .img-shadow {
    margin-top: -70px;
  }
  .light h2 {
    font-size: 1.5em;
    line-height: 1.25em;
  }
  .default-btn {
    line-height: 18px;
  }
  .line-box-3 .mls-content,
  .line-box-3 .trusted-logo {
    width: 32%;
    border: 1px solid #dee2e6 !important;
    padding: 15px;
  }
  .line-box-3 .mls-content,
  .line-box-4 .mls-content {
    border: 1px solid #dee2e6 !important;
    padding: 15px 36px;
  }
  footer {
    padding: 30px 10px 20px;
  }
  footer .info svg {
    width: 30px;
  }
  footer ul li + li {
    margin-top: 5px;
  }
  .verticalFlip span {
    line-height: 36px;
    width: 100%;
    left: 0;
  }
  .verticalFlip {
    height: 40px;
  }
  .banner-sec.video-sec {
    padding: 50px 10px 130px;
    background-position: 55% 50%;
  }
  .overlay-white:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 70%);
  }
  .home-cta {
    margin: 0 10px;
  }
  .design-block,
  .marketing-sec .video-block {
    margin-left: 0;
  }
  .h5 {
    font-size: 17px;
  }
  .line-box-3 h5,
  .line-box-4 h5 {
    font-size: 18px;
  }
  .home-cta img {
    margin-top: -65px;
  }
  .timeline__event__content {
    width: calc(75vw - 84px);
  }
  .callcenter-sec:before,
  .careers-sec:before,
  .marketing-sec:before,
  .software-sec .bg-overlay {
    width: 100%;
  }
  .callcenter-sec:before {
    left: 0;
  }
  .bg-overlay-prod,
  .team-overlay {
    width: 100%;
    top: 0;
    left: 0;
  }
  .banner-sec.callcenter-sec,
  .banner-sec.idea-sec,
  .banner-sec.kpo-sec,
  .banner-sec.marketing-sec,
  .banner-sec.product-sec,
  .banner-sec.software-sec,
  .inner {
    padding: 160px 0 60px;
  }
  .case-box {
    min-height: 290px;
    padding: 20px;
  }
  .case-study-sec h1 {
    font-size: 54px;
    line-height: 64px;
  }
  .heading label {
    font-size: 52px;
  }
  .clients h2 {
    font-size: 23px;
  }
  .design-block {
    padding: 0;
    box-shadow: none;
  }
  .num {
    width: 42px;
    height: 42px;
    font-size: 22px;
    line-height: 34px;
  }
  .accordion:before {
    left: 20px;
  }
  .mw-500 {
    margin: 0 auto;
    max-width: 700px;
  }
  .idea-sec {
    height: auto;
  }
  footer .text a {
    letter-spacing: -1px;
    font-size: 24px;
  }
  .tab-pane .line-box-3 .mls-content,
  .tab-pane .line-box-4 .mls-content {
    padding: 15px;
  }
  .mySwiper:after,
  .mySwiper:before {
    display: none;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    transform: none;
    padding: 0 6px;
  }
}
@media screen and (max-width: 992px) {
  .line-box-3 .mls-content,
  .line-box-3 .trusted-logo {
    padding: 15px;
    width: 50%;
  }
  .client-sec {
    padding: 180px 10px 60px;
    background-position: left;
    text-align: center;
  }
  .hero-banner h1 {
    font-size: 49px;
  }
}
.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media screen and (max-width: 1200px) {
  .hero-banner h1 {
    font-size: 49px !important;
  }
  .gen-home {
    padding: 150px 0 50px !important;
  }
  .ivideo-sec {
    padding: 120px 0 60px;
    text-align: center;
  }
}
@media screen and (max-width: 480px) {
  .title-inner {
    font-size: 30px !important;
  }
  .scroll-navigator {
    top: 78px !important;
  }
}
.hero-b2b-2 img {
  filter: brightness(0.75);
}
@media screen and (max-width: 1024px) {
  .sdr-banner .bottom-sec,
  .sdr-banner .top-sec {
    position: relative;
    width: 100%;
    margin-top: 30px;
  }
  .process-sec .sb-block {
    min-height: auto;
    text-align: center;
  }
  .w-450,
  .w-520 {
    width: 100%;
  }
  .sdr-banner .top-sec {
    right: 0;
  }
  .sdr-banner {
    padding-top: 0px;
    padding-bottom: 30px;
  }
  .sdr-banner .bottom-sec {
    bottom: 0;
  }
  .sec-title {
    font-weight: 500;
    font-size: 45px !important;
    line-height: 57px;
    padding-top: 30px;
  }
  .cta-sec.sec-1 .sec-title {
    font-size: 54px;
    line-height: 62px;
    letter-spacing: -2px;
  }
  .cta-sec.sec-1 {
    padding: 70px 0;
  }
  .cta-sec.sec-2 {
    padding: 70px 0 85px;
  }
}
@media screen and (max-width: 1399.98px) {
  .sdr-banner .banner-text {
    font-size: 170px !important;
  }
}
@media screen and (max-width: 1024.98px) {
  .hero-b2b-1 {
    display: none;
  }
  .hero-b2b-2 {
    display: block;
  }
  .hero-b3b-3-img {
    position: relative;
  }
  .hero-b3b-3-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sdr-banner .banner-text {
    font-family: "Frank Ruhl Libre", serif;
    font-weight: 400;
    letter-spacing: 3px;
    position: absolute;
    text-align: center !important;
    top: 40px;
    margin-right: 54px;
    margin-left: 54px;
    line-height: normal;
    font-size: 54px !important;
    left: 20%;
    width: 50%;
    z-index: 4;
  }
}
@media screen and (max-width: 992px) {
  .h-75,
  .height-50,
  .vh-100 {
    height: auto !important;
  }
  .service-sec-bg {
    background: #f4f4f4 !important;
  }
  .title-inner {
    font-size: 42px !important;
  }
  .page-section img {
    margin-bottom: 30px;
  }
  .font-size-34 {
    font-size: 24px !important;
  }
  .inner {
    padding: 60px 0 60px;
  }

  .page-section {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .sdr-banner .banner-text {
    position: static;
    line-height: 95%;
    letter-spacing: 0;
    margin-right: 0;
    margin-left: 0;
    text-align: center !important;
    width: 100%;
    font-size: 34px !important;
    color: #fff;
  }
  .font-size-34 {
    font-size: 24px !important;
  }
}
@media screen and (min-width: 1024px) {
  .callcenter-sec:before {
    width: 70% !important;
    left: 0 !important;
  }
}
@media screen and (max-width: 1201px) {
  .bg-green1,
  .bg-green1 .banner-content,
  .bg-green1 .banner-sec {
    text-align: center;
  }
  .marketing-sec {
    padding-top: 120px !important;
  }
  .super-container {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
  .bg-green1:before,
  .down-sec.bg-light:before {
    display: none;
  }
  .bg-green1 {
    padding: 50px;
  }
  .bg-green1 .banner-sec {
    margin-bottom: 20px;
    padding-right: 100px;
    padding-left: 100px;
  }
}
.cp-mrg {
  margin-top: 0 !important;
}
@media screen and (min-width: 576px) and (max-width: 992px) {
  .bg-dk-blue:after,
  .bg-green:after,
  .bg-yellow:after {
    content: "";
    position: absolute;
    width: 101vh;
    height: 100%;
    left: -100vh;
  }
  .bg-green:before {
    height: 100%;
  }
  .bg-green:after {
    background: #9cc11b;
    z-index: -1;
  }
  .bg-yellow:after {
    background: #e4c652;
    z-index: -2;
  }
  .bg-dk-blue:after {
    background: #0a4b5c;
    z-index: -1;
  }
  .react-slideshow-container + ul.indicators {
    margin-bottom: 40px;
  }
}
.resourse-bg {
  background-image: url(../src/app/assets/images/resource-bg.jpg);
}
.overlay-light {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(6 58 89 / 70%);
}
.resourse-bg .overlay-light {
  background: rgba(6, 58, 89, 0.7) !important;
}
.bg-yellow1,
.yellow-bg {
  background: #ffbf00;
}
.inner-sec:before {
  content: "";
  background: #dee2e6;
  top: 0;
  width: 50%;
  position: absolute;
  height: 100%;
  left: 0;
}
.masonry {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 1em;
  -moz-column-gap: 1em;
  column-gap: 1em;
  margin: 1.5em;
  padding: 0;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  font-size: 0.85em;
}
.item,
.item-box-interest {
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  display: inline-block;
}
.item {
  background: #f5f5f5;
  padding: 60px 40px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.item-box-interest {
  background: #fff;
  padding: 30px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.07);
}
.author {
  font-size: 1.3em;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: normal;
  margin-bottom: 0;
}
@media only screen and (max-width: 320px) {
  .masonry {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media only screen and (min-width: 321px) and (max-width: 768px) {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media only screen and (min-width: 1201px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

.btn-wraper a,
.text-name {
  font-weight: 600;
  font-family: "Geologica", "Poppins", sans-serif !important;
}
.btn-wraper a {
  color: #0c5b79;
  font-size: 15px;
  transition: 0.2s ease-in-out;
  font-family: "Geologica", "Poppins", sans-serif !important;
}
.btn-wraper a:hover {
  color: #bfbfbf;
  transition: 0.2s ease-in-out;
}
.case-list .border-line,
.case-list .underline-border i {
  color: #f5f5f5 !important;
}
.btn-wraper a:hover svg {
  margin-left: 5px;
  transition: 0.2s ease-in-out;
}
.case-box-2,
.serv-card-wrap {
  margin-bottom: 30px;
}
.react-multiple-carousel__arrow {
  background: #0c5b79 !important;
  min-height: 53px !important;
  z-index: 98 !important;
}
.sec-primary-ic {
  background: #0c5b79;
  box-shadow: rgb(19 68 90 / 35%) 0 28px 44px;
}
.text-sp-primary {
  color: #0c445c;
}
.gradient-l-blue {
  color: #52bdc7;
}
.case-list .border-line {
  border-color: #f5f5f5 !important;
}
.cus-hero-padding {
  padding: 200px 0 150px;
}
.h1.big,
h1.big {
  margin-bottom: 20px;
  font-size: 4.375rem;
}
.hero-section-title p {
  font-size: 28px;
  padding: 0 150px;
  line-height: 1.4;
}
[class|="rectangle-element"] [class|="info"] [class|="image"] img,
[class|="square-element"] [class|="info"] [class|="image"] img {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
[class|="sectiontitle-element"] h2,
[class|="sectiontitle-element"] h3 {
  line-height: 1.25;
  font-size: 2.5rem;
  padding-bottom: 1.25rem;
}
.menu-icon svg,
.text-name,
[class|="sectiontitle-element"] p {
  font-size: 20px;
}
[class|="list-element"] ul li:before {
  content: "✔";
  font-family: feather;
  color: #222220;
  left: 0;
  position: absolute;
}
[class|="list-element"] ul li {
  font-size: 1rem;
  color: #222;
  font-weight: 400;
  padding: 0.5rem 0 0 1.875rem;
  position: relative;
  line-height: 1.6;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
[class|="icon-element"][class*="-box"][class*="-border"]:hover {
  border-color: rgba(205, 205, 205, 0.5);
}
[class|="icon-element"][class*="-box"]:hover {
  background-color: #fff;
  -webkit-box-shadow: 14px 17px 17px rgba(34, 34, 32, 0.1);
  box-shadow: 14px 17px 17px rgba(34, 34, 32, 0.1);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
[class|="icon-element"][class*="-box"] {
  padding: 1.875rem;
  border-radius: 10px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.my-card {
  background-color: #e7eaff;
  border-radius: 10px;
  padding: 40px;
}
.cust-icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  text-align: center;
  line-height: 54px;
}
.cust-icon img {
  width: 30px;
}
[class|="icon-element"] [class|="info"] [class|="text"] {
  color: #212529;
  font-weight: 400;
  opacity: 1;
  line-height: 1.45;
}
[class|="icon-element"][class*="-round"] [class|="text"],
[class|="icon-element"][class*="-square"] [class|="text"] {
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 1rem;
}
[class|="sectiontitle-element"][class*="center"] {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}
.menu-icon svg {
  fill: #0c5b79;
}
.case-box-2 {
  min-height: 250px;
  background-color: #173947;
  border: 1px solid #969696;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  flex-direction: column;
}
.case-box-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 102px;
}
.case-box-details h6 {
  font-size: 18px;
  margin-bottom: 6px;
}
.case-box-details p {
  font-size: 16px;
  line-height: normal;
  color: #ffffff;
}
section {
  padding: 98px 0;
}
.core-img-wrapper img {
  margin-left: -80px;
}
.name-wrapper {
  width: 180px;
  height: auto;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 24px;
  left: 310px;
  text-align: start;
}
.text-desc {
  font-size: 14px;
}
@media screen and (max-width: 1198.98px) {
  .font-size-34 {
    font-size: 24px !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1310px) {
  .name-wrapper {
    left: 330px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1198px) {
  .name-wrapper {
    left: 590px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .name-wrapper {
    left: 460px;
  }
}
@media screen and (max-width: 767px) {
  .scroll-navigator {
    padding: 0 !important;
  }
  .ideatoproduct-page ul {
    padding: 12px !important;
  }
  .hero-banner h1 {
    font-size: 49px;
  }
  .banner-sec {
    height: auto;
  }
  .masthead .row,
  .team-image img,
  .w-450,
  .w-520 {
    width: 100%;
  }
  button.cs-btn.btn-left.nav {
    margin-left: 0;
    bottom: -39px;
    left: 45%;
    transform: translateX(-50%);
  }
  button.cs-btn.btn-right.nav {
    margin-right: 0;
    bottom: -39px;
    right: 40%;
    transform: translateX(50%);
  }
  .sss-sec {
    padding: 170px 0 85px;
    background-size: auto;
    background-position: left;
    text-align: center;
  }
  .testimonial-content h5 {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  .banner-sec.marketing-sec {
    padding: 120px 10px 40px;
  }
  h1 {
    font-size: 1.55rem;
  }
  .accordion:before,
  .timeline__event__icon:after,
  .timeline__event__icon:before {
    display: none;
  }
  .masthead {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
  }
  .case-box {
    min-height: 160px;
    border: 3px solid #302b31;
  }
  section.boost-sec.leader-sec {
    text-align: center;
  }
  .cs-title {
    font-size: 26px;
  }
  .prod-block {
    height: auto;
    box-shadow: 0 15px 30px rgb(0 0 0 / 8%);
  }
  .timeline__event {
    flex-direction: column;
    align-self: center;
  }
  .timeline__event__content {
    width: 100%;
    padding: 0 10px;
    background: transparent;
    box-shadow: none !important;
    text-align: center;
  }
  .timeline__event__icon {
    border-radius: 6px;
    margin: 0 0 20px;
    padding: 15px;
  }
  .timeline__event:nth-child(odd) .timeline__event__date,
  .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }
  .timeline__event:nth-child(odd) {
    flex-direction: column;
    align-self: center;
  }
  .banner-sec h1,
  .banner-sec h2 {
    font-size: 1.7em !important;
    line-height: 1.4;
  }
  .core-img-wrapper img {
    margin-left: 0;
    margin-bottom: 12px;
  }
  .img-fluid.banner-img-wrap {
    margin-left: 0 !important;
    margin-bottom: 30px;
  }
  .name-wrapper {
    width: 40%;
    margin: auto auto 30px;
    position: unset;
    display: block;
    text-align: center;
  }
}
.sticky-left-container {
  padding: 0;
  margin: 0;
  position: fixed;
  right: -136px;
  top: 40%;
  width: 200px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 992px) {
  [class|="sectiontitle-element"][class*="center"] {
    max-width: 100%;
  }
  .container.super-container::before {
    position: absolute;
    content: "";
    background-color: #00000045;
    width: 1200px;
    height: 100%;
    z-index: -1;
    top: 0;
    left: -200px;
  }
  button.cs-btn.btn-right.nav {
    margin-right: 0;
    bottom: -39px;
    right: 40%;
    transform: translateX(50%);
  }
  button.cs-btn.btn-left.nav {
    margin-left: 0;
    bottom: -39px;
    left: 45%;
    transform: translateX(-50%);
  }
  .pb-80 {
    padding-bottom: 80px !important;
  }
  .sticky-left-container {
    padding: 0;
    margin: 0;
    position: fixed;
    right: 5px;
    top: 40%;
    width: 50px;
    z-index: 2;
    display: flex;
    flex-direction: column;
  }
  .h1.big,
  h1.big {
    font-size: 3.5rem;
  }
  .hero-section-title p {
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .sticky-left-container {
    display: none;
  }
  .inner-card-cust {
    margin-top: 12px;
  }
  .main-title.sectiontitle-elements h2 {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 482px) {
  .h1.big,
  h1.big {
    font-size: 2.5rem;
  }
  .inner-sec:before {
    width: 100%;
  }
}
.copyright-link,
.copyright-link:hover {
  color: rgb(82, 189, 199) !important;
  text-decoration: underline !important;
}
.form-control:focus {
  border-color: #959699 !important;
}
.timeline__event__icon.scope-none::before {
  display: none;
}
.timeline__event:nth-child(odd) .timeline__event__icon:before {
  height: 150px;
}
.team-desc {
  min-height: 126px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grecaptcha-badge {
  visibility: hidden !important;
}
.sharp-object::after {
  display: none;
}
@media screen and (min-width: 1400px) {
  .item-box-interest {
    height: 100%;
  }
  .sharp-object::after {
    content: "";
    position: absolute;
    width: 220px;
    height: 220px;
    border: 10px solid #f6c65e;
    left: -160px;
    top: 145px;
    transform: rotate(45deg);
    z-index: -1;
  }
}
@media screen and (max-width: 1024px) {
  .sdr-banner .banner-text {
    position: static;
    line-height: 95%;
    letter-spacing: 0;
    text-align: center !important;
    width: 74%;
    font-size: 54px !important;
    color: #fff;
    margin: 0 36px;
  }
}
@media screen and (max-width: 1198.98px) {
  .lg-justify-center {
    justify-content: center;
  }
}

@media screen and (max-width: 576px) {
  .sss-sec {
    padding: 170px 12px 85px;
  }
}
.react-slideshow-container + ul.indicators {
  margin-bottom: 20px;
}
.each-slide-effect {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
/* News letter subscription */

/* SubscribeForms Styles */

.text-box > h4 {
  letter-spacing: 0.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bolder;
  line-height: 1.2;
}
.text-box > p {
  margin-bottom: 0;
  font-size: 12px !important;
  line-height: 1.6;
  font-weight: 500 !important;
}

#subscribe-input {
  background-color: #ffffff !important;
  border: 1px solid #007bb6 !important;
  box-shadow: unset !important;
  padding-right: 0px !important;
  border-radius: 0%;
  height: 50px !important;
}
#subscribe-input-1 {
  background-color: #ffffff !important;
  border: none !important;
  box-shadow: unset !important;
  padding-right: 0px !important;
  border-radius: 0%;
  border-right: 0.6px solid rgb(199, 198, 198) !important;
}

.subcribes-submit {
  position: static !important;
  /* background-color: #0c5b79;
  color: #FFF !important; */
  background-color: #0c5b79 !important;
  padding: 10px 10px 10px 10px;
  font-size: 14px;
}

#subcribes-submit {
  color: #ffffff;
}
.agree-check:checked {
  background-color: #007bb6 !important;
  border-color: #007bb6;
}
.agree-check:focus {
  box-shadow: 0 0 0 0 #007bb6;
}
.agree-text {
  font-size: 12px !important;
  font-weight: 500;
}
.agree-text span {
  padding-left: 3px;
}
.agree-text svg {
  margin-bottom: 4px;
}

/* ========== Blog Style Update ========== */

.rec-blog-hov li:hover {
  color: #0c5b79;
}
.blog-banner h2 {
  position: absolute;
  inset: 50% 0 0 50%;
  transform: translate(-50%, -50%);
  font-size: 58px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  display: grid;
  align-content: center;
}

.blog-block .border-line {
  border-color: #0c5b79;
}

.blog-block a.underline-border i,
.blog-sec a.underline-border {
  color: #0c5b79;
}
.blog-block:hover a,
.blog-block:hover a i {
  color: #bfbfbf;
  -webkit-background-clip: inherit;
  -webkit-text-fill-color: inherit;
  -webkit-box-decoration-break: inherit;
  box-decoration-break: none;
  background: transparent;
}
.blog-block:hover .border-line {
  border-color: #bfbfbf;
  color: #bfbfbf;
}
.blog-banner {
  position: relative;
}
.blog-inner li,
.blog-inner p {
  font-size: 17px;
  line-height: 1.5em;
}
.blog-banner img {
  filter: brightness(44%) contrast(100%) saturate(100%) blur(0px)
    hue-rotate(0deg);
  width: 100%;
  height: 380px;
}
.blog-list h2 {
  font-size: 1.5rem;
  color: #000;
  font-weight: 500;
}
.blog-list hr {
  margin-top: 25px;
  margin-bottom: 22px;
}
.blog-author,
.meta-date-text {
  font-size: 1.1rem;
  opacity: 0.7;
  font-weight: 300;
}
.blog-list-body p {
  font-size: 16px !important;
  color: #000;
  line-height: 1.7 !important;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.blog-detail-social-media ul,
.blog-detail-tags ul {
  display: flex;
  flex-wrap: wrap;
}
.blog-detail-tags ul li {
  text-transform: capitalize;
  background-color: #fff;
  padding: 5px 10px;
  border: 1px solid #e1e1e1;
  color: #222;
  border-radius: 6px;
  font-size: 13px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  word-break: break-all;
  display: inline-block;
  transition: 0.2s;
}
.blog-detail-tags ul li a {
  color: #222;
}
.blog-detail-tags ul li:hover {
  background-color: #e1e1e1;
  color: #222;
}
.blog-detail-social-media ul li {
  margin-right: 0.5rem;
  width: 35px;
}
.blog-detail-heading h6 {
  font-size: 18px;
  color: rgb(0, 0, 0, 0.5);
  font-weight: 500;
  margin-bottom: 20px;
}
.blog-detail-heading h2 {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 30px;
}
.blog-detail-elevation p {
  margin-bottom: 20px;
}
.blog-detail-elevation h4 {
  font-size: 1.4rem;
  margin: 20px 0;
  text-transform: none;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
}
.blog-details-right ul li {
  font-size: 1rem !important;
  list-style: none !important;
  border-bottom: 1px solid #e1e1e1;
}
.blog-details-right li,
.blog-details-right p {
  font-size: 1.1rem;
  line-height: 1.7 !important;
  font-weight: 400;
  padding-bottom: 15px;
  padding-top: 15px;
}
.blog-detail-author h6 {
  font-weight: 400;
  line-height: 1.7;
}
.blog-list {
  padding: 3rem 4rem;
}
.item-box-interest p {
  line-height: 1.7 !important;
}
.blog-detail-author h4 {
  padding-top: 2px;
}
.item-blog,
.other-blog {
  display: inline-block;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.item-blog {
  padding: 1.5rem;
}
.blog-author,
.meta-date-text {
  font-size: 0.9rem !important;
  opacity: 0.7;
  font-weight: 300;
}
.blog-detail-heading h2 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
}
.blog-detail-elevation p,
ul.blog-ul li {
  margin-bottom: 1rem;
  font-size: 1.12rem !important;
  line-height: 1.82222 !important;
  color: #000 !important;
  font-weight: 400;
}
.blog-detail-elevation h4 {
  letter-spacing: -0.01em;
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 1.35rem !important;
  font-weight: 600;
  line-height: 1.16667;
}
.blog-detail-elevation b {
  font-weight: 700 !important;
}
.blog-details-right,
.subscribe-container {
  margin-left: 28px;
}
@media screen and (max-width: 991.98px) {
  .blog-details-right,
  .subscribe-container {
    margin-left: 0px;
  }
}
.blog-detail-elevation a {
  color: #0c5b79;
  font-weight: 700 !important;
}
span.high-lighted,
.blog-detail-elevation p b.text-dark {
  font-weight: 600 !important;
  color: #000000 !important;
}
.text-black {
  font-weight: 700 !important;
}
.blog-ul {
  list-style-type: disc !important;
}
.blog-ul li {
  margin-left: 32px;
}
/* ---------Updated Design--------- */
.special-design {
  background-color: #f6f9fc;
  border-radius: 8px;
  overflow: hidden;
}
.special-image {
  width: 100%;
}
.item-blog h2 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.42;
}
.special-design:hover .item-blog h2,
.other-blog:hover h2,
.featured-blog-list:hover h2 {
  text-decoration: underline !important;
}
.special-design .blog-list-body p {
  font-size: 0.875rem !important;
}
.featured-blogs h3 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.75;
  border-bottom: 2px solid #ffbf00;
  margin-bottom: 0.5rem;
  padding-bottom: 0.25rem;
}
.featured-blog-list {
  padding-bottom: 1rem;
  border-bottom: 2px solid #dbe4ed;
  margin-bottom: 0.75rem;
}
.featured-blog-list:last-child {
  border-bottom: 0px !important;
}
.featured-blog-list h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.57;
  margin-bottom: 0.5rem;
}
.mb-blog {
  margin-bottom: 1.75rem;
}
.other-blog {
  margin-bottom: 2rem;
}
img.blog-image {
  width: 100%;
  margin-bottom: 0.5rem;
}
.other-blog h2 {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.57;
}
.blog-description {
  font-size: 0.875rem;
  margin: 0.5rem 0rem;
}
.other-details {
  font-size: 0.75rem;
  color: #516f90;
  margin: 0;
}
.blog-contents {
  display: flex;
}
.blog-share {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.share {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.share a {
  margin-bottom: 1rem;
}
.sticky-sharebutton {
  position: sticky;
  height: 100%;
  top: 120px;
  z-index: 8;
  margin-right: 1.125rem;
}
.home-blog {
  padding: 4rem 0rem;
}

.case-box a {
  color: #ffbf00;
}
.case-box .sub-text {
  font-size: 0.75rem !important;
}
.case-box:hover .sub-text,
.case-box .sub-text {
  color: #ffffff !important;
}
.learn-more,
.learn-more:hover {
  background-color: transparent;
}
.learn-more span,
.learn-more i {
  font-size: 0.75rem !important;
}

.img2 img {
  width: 128px;
}
@media screen and (max-width: 991px) {
  .last-one:last-child {
    display: none;
  }
  .blog-list {
    padding: 2rem 1rem;
  }
  .featured-blogs h3 {
    margin-top: 1rem;
  }
  .home-blog {
    padding: 2rem 0rem !important;
  }
  .cus-pd-24-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .cus-pd-24-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .m-left {
    position: static;
    transform: translate(0%, 0%);
  }
  .b-r-t-b,
  .b-r-r-b {
    border-radius: 10px;
  }
  .img2 img {
    width: 124px;
  }
}

@media screen and (max-width: 767px) {
  .img2 img {
    min-width: 88px;
    width: 88px;
  }
}
@media screen and (max-width: 456px) {
  .img2 img {
    min-width: 74px;
    width: 74px;
  }
}

.cus-pd-24 {
  padding-left: 2.55rem;
  padding-right: 2.55rem;
}

.header-navbar.mobile-menu .site-navbar.menu-on .primary-menu li.active a,
.megamenu-section-container2 ul li.active a.sub-link,
.megamenu-section-container2 ul li.active a.sub-link2 {
  color: #0c5b79;
}

.thumbnail-container {
  position: relative;
  cursor: pointer;
}

.custom-thumbnail {
  display: block;
  width: 100%;
  height: auto;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  pointer-events: none;
}

.play-button svg {
  width: 100%;
  height: 100%;
}

.bg-dk-blue {
  background-color: #0a4b5c;
  background-image: url(../src/app/assets/images/webdesign-media/bg2.png);
  background-position: 15% 40%;
  background-size: cover;
}

@media screen and (max-width: 991px) {
  .bg-dk-blue {
    background-image: none;
  }
}

.impact ul li {
  margin-left: 2rem;
}

.shadow-md {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.btn.cust-btn-primary.secondary-main {
  background: #0c5b79;
  color: #fff;
}

.btn.cust-btn-primary.secondary-main:hover {
  background: #0c3b48;
  color: #fff;
}

.small,
small {
  font-size: 0.875em !important;
}
@media screen and (min-width: 991px) {
  .w-lg-75 {
    width: 75% !important;
  }
}
@media screen and (max-width: 575px) {
  button.cs-btn.btn-left.nav,
  button.cs-btn.ib-btn-left.nav,
  button.cs-btn.btn-right.nav {
    display: none !important;
  }
}
.career-btn{
  -webkit-appearance: unset;
}

.header-navbar .primary-menu li .submenu li.active a {
  color: #0c5b79;
}

.plug-play-page p {
  font-size: 18px !important;
}
p.mini-text{
  font-size: 13px !important;
}
