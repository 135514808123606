.hero-banner h1,
.title {
  font-weight: 400;
}

.title,
.title-inner {
  font-size: 54px;
}

.service-sec-bg,
.turn-idea-sec.bg-dark {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ideatoproduct-page .default-btn,
.ideatoproduct-page h1,
.ideatoproduct-page h2,
.ideatoproduct-page h3,
.ideatoproduct-page h4,
.ideatoproduct-page h5,
.ideatoproduct-page h6,
.ideatoproduct-page p,
.ideatoproduct-page ul li,
body {
  font-family: "Noto Sans", sans-serif !important;
  font-optical-sizing: auto;
}

.hero-bg {
  background-color: #f5f5f5;
  padding: 160px 0 60px;
}

.hero-banner h1 {
  font-size: 68px;
  color: #373732;
}

.scroll-navigator {
  position: sticky;
  padding-top: 40px;
  top: 300px;
  position: -webkit-sticky;
  width: 27%;
  float: left;
  margin-left: -50px;
}

.scroll-navigator a.active {
  color: #13303c !important;
  font-size: 16px;
  font-weight: 600;
}

.scroll-navigator a:hover {
  color: #0a4b5c !important;
  font-weight: 500;
}

.ideatoproduct-page ul li a {
  font-size: 16px;
}

.ideatoproduct-page ul li {
  margin-bottom: 1rem;
}

.ideatoproduct-page ul li:last-child {
  margin-bottom: 0;
}

.ideatoproduct-page ul {
  padding: 1em;
  border-radius: 10px;
  border: 1px solid #dde0e3;
}

@media screen and (min-width: 1400px) {
  .container-fluid-lg {
    max-width: 1400px;
    width: 80%;
  }
}

.scroll-navigator-content {
  margin-left: 26%;
}

.page-section {
  padding-top: 30px;
  height: 100%;
}

.sub-title {
  font-size: 2.4rem;
  font-weight: 300;
}

.benefits-sec p {
  color: #5b5c49;
  font-family: Chivo, sans-serif;
}

.turn-idea-sec.bg-dark {
  background-image: url(../../../app/assets/images/idea-to-Product/cta-bg.jpg);
}

.turn-idea-sec h5 {
  font-size: 25px;
  font-family: Chivo, sans-serif;
  font-weight: 900;
}

.service-sec-bg {
  background-image: url(../../../app/assets/images/idea-to-Product/service.jpg);
}

.testimonial-sec h5 {
  font-size: 18px;
  line-height: normal;
  color: #5b5c49;
  font-weight: 400;
}

.testimonial-sec .quotes {
  font-size: 108px;
  height: 70px;
  color: #5b5c49;
}

.testimonial-sec .br-53 {
  border-radius: 50%;
}

.cs-btn.idp-test_btn {
  font-size: 28px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #0c5b79;
  border-radius: 50%;
  color: #0c5b79;
  padding: 0 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cs-btn.idp-test_btn:hover {
  background-color: #0c5b79;
  color: #fff;
  outline: 0;
  transition: 0.3s;
}

button.cs-btn.idp-test_btn.cst-right.nav {
  top: 20px;
}

button.cs-btn.idp-test_btn.cst-left.nav {
  top: 20px;
  position: absolute;
  left: unset;
  right: 60px;
}

.ideatoproduct-page .react-slideshow-wrapper .images-wrap.horizontal {
  align-items: start;
}

.get-in-touch-sec a {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 25px;
}

.bookanmeeting-2 .form-control {
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #5c5c5c !important;
}

.bookanmeeting-2 .form-label {
  color: #373732;
  font-family: Chivo, sans-serif;
}

.form-check,
.form-control,
.form-label,
form p {
  font-size: 17px;
  color: #181818;
  font-weight: 400;
}

.for-lg {
  display: block;
}

.for-md {
  display: none;
}

@media screen and (max-width: 1399px) {
  .scroll-navigator-content {
    margin-left: 30%;
  }
  .scroll-navigator {
    top: 90px;
    margin-left: 0;
    width: 26%;
  }

  .page-section {
    padding-top: 100px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .for-md {
    display: block;
  }

  .for-lg {
    display: none;
  }

  .scroll-navigator a.active {
    color: #0c5b79 !important;
    background-color: #52bdc742;
    font-size: 16px;
  }

  .scroll-navigator {
    padding: 20px;
    background-color: #fff;
    top: 75px;
    width: 100%;
    z-index: 8;
  }

  .scroll-navigator ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 12px;
  }

  .scroll-navigator ul li {
    margin-bottom: 0px;
  }

  .scroll-navigator ul li a {
    padding: 10px;
    display: block;
  }

  .scroll-navigator-content {
    width: 100%;
    margin-left: 0;
  }

  #scrollcontain-main {
    padding: 0 36px 36px;
  }

  .item {
    padding: 0 40px;
  }
}

.item,
.list-inline-item {
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  #scrollcontain-main {
    text-align: center;
  }

  .item {
    padding: 0 20px;
  }
}

.text-bk-dark {
  color: #13303c;
}

.item {
  background: 0 0;
}

.cust-btn-primary,
.default-btn {
  font-weight: 700;
  padding: 18px 26px;
  text-transform: uppercase;
  line-height: 1;
  background: #ffbf00;
  color: #000;
}

@media screen and (max-width: 767px) {
  .testimonial-content h5 {
    font-size: 18px !important;
    line-height: normal !important;
  }

  section.home-sec.hero-bg h1 {
    font-size: 42px;
    font-weight: 400;
  }

  .title,
  .title-inner {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .section-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .page-section {
    padding-top: 46px;
  }
}

.cust-btn-primary {
  font-size: 15px;
  height: auto;
  margin: 0;
  width: auto;
  border-radius: 6px;
  letter-spacing: -0.1px;
  border: 0;
}

.default-btn,
.footer-social li a {
  display: inline-block;
  text-align: center;
  font-size: 16px;
}

.cust-btn-primary.light-variant,
.menu_fixed .cust-btn-primary {
  background: #0c5b79;
  color: #fff;
}

.menu_fixed .cust-btn-primary:hover {
  background: #0c3b48;
  color: #fff;
}

.default-btn {
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  border: 0;
  cursor: pointer;
  position: relative;
  text-decoration: none !important;
  border-radius: 6px;
}

.scroll-navigator ul li a {
  color: #14133a;
  text-decoration: none;
}

.bg-dark {
  background-color: #0c3b48 !important;
}

.form-control,
.react-tel-input .form-control {
  border: 1px solid #b0b0b0 !important;
  border-radius: 4px !important;
}

header {
  position: relative;
  z-index: 99;
}

.pdt-12 {
  padding-top: 12px !important;
}

.pdt-15 {
  padding-top: 15px !important;
}

.pdb-12 {
  padding-bottom: 12px !important;
}

.pdb-15 {
  padding-bottom: 15px !important;
}

.margin-minus {
  margin-top: -101px;
}

.font-size-20 {
  font-size: 20px !important;
}

.pricing li,
p {
  font-size: 17px;
}

.banner-sec p.para,
p,
ul li {
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: normal;
  margin-bottom: 0;
}

.item {
  padding: 60px 40px 0px;
  border-radius: 6px;
  width: 100%;
  box-shadow: none;
}

footer a,
footer p {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.footer-social li {
  margin: 0;
}

/* .footer-social li a {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 60px;
} */

footer ul li {
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 15px;
  text-decoration: none;
}

footer a:hover,
footer h4,
footer h5 {
  color: rgb(255 255 255);
  opacity: 1;
}

.fixed-btm-top {
  position: fixed;
  z-index: 999;
  right: 10px;
  bottom: 90px;
}

.fixed-btm-top a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background: #52bdc7 !important;
  padding: 12px;
  border-radius: 5px;
}

.fixed-btm-top a:hover {
  background: #4cb0b9 !important;
  color: #000;
}

.fixed-btm-top.reveal a {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 575px) {
  #scrollcontain-main {
    padding: 0 0 36px;
  }

  .section-padding {
    padding: 50px 0;
  }

  .scroll-navigator {
    padding: 0;
  }

  .service-sec-bg {
    background-image: none !important;
  }

  .section-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .header-btn {
    display: none;
  }

  .sm-justify-center {
    justify-content: center !important;
  }
}

@media screen and (max-width: 991px) {
  .service-sec-bg {
    background-image: none !important;
  }
}

.error-msg {
  position: absolute;
  font-size: 13px;
}

.take-act-phone .form-control {
  border: none !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0 !important;
}

.take-act-phone .flag-dropdown {
  background-color: unset !important;
  border: none !important;
}

.thankyou-page-wrapper {
  height: 100%;
  min-height: calc(100vh - 124px);
}

.home-cta {
  border-radius: 20px;
  padding: 60px;
}

.bg-safon {
  background-color: #52bdc7;
}

.home-cta img {
  border-radius: 20px;
  box-shadow: 0 10px 22px #00000029;
  margin-top: -134px;
}
.inner {
  padding: 150px 0 80px;
}
.scroll-up a {
  position: fixed;
  bottom: 10%;
  right: 10px;
  background: #52bdc7;
  width: 2em;
  height: 2em;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  text-align: center;
  line-height: 2.2;
  color: #1c1c1c;
  z-index: 99;
  right: 0;
}

.scroll-up a i {
  font-size: 1.2em;
}

.grecaptcha-badge {
  visibility: hidden !important;
}
