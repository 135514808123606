.header-inner,
.themesflat-container {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}
.idea-booster-page p,
.wg-banner-1 .content p {
  color: #fff;
  line-height: 1.5;
}
.btn-glow,
.tf-button {
  color: #000;
  font-weight: 600;
}
.idea-booster-page .default-btn,
.idea-booster-page div,
.idea-booster-page h1,
.idea-booster-page h2,
.idea-booster-page h3,
.idea-booster-page h4,
.idea-booster-page h5,
.idea-booster-page h6,
.idea-booster-page ul li {
  font-optical-sizing: auto;
}
body,
html {
  font-family: "Noto Sans", sans-serif !important;
}
.idea-booster-page p {
  font-size: 20px;
  font-weight: 400;
}
.themesflat-container {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}
.tf-button {
  background-color: #ffbf00;
  border: 2px solid #ffbf00;
  border-radius: 50px;
  padding: 20px 30px !important;
  height: auto !important;
  line-height: 1.2;
  text-align: center;
  width: max-content;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-decoration: unset;
}
.heading-section .sub-title,
.text-gradient {
  line-height: 33px;
  margin-bottom: 10px;
  letter-spacing: -0.66px;
}
.header-inner {
  padding: 25px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.logo-main {
  width: 200px;
}

.custom-underline {
  position: relative;
  z-index: 1;
}

.testi-card-custom {
  background-color: #214453;
  box-shadow: none;
  margin-right: 20px;
}
.job-role {
  font-size: 14px !important;
  color: #ffffff !important;
}
.blur-ball .ball-1 {
  position: absolute;
  top: -200px;
  right: -55px;
}
.blur-ball .ball-2 {
  position: absolute;
  bottom: -179px;
  left: -56px;
}
.cta-arrow-sec,
.cust-spacing,
.vision-btn {
  margin-top: 60px;
}

.gallery-sub-head {
  font-size: 22px;
  background-clip: text !important;
  -webkit-background-clip: text;
}
.gallery-sub-head,
.modal-text-cont h2 {
  background: #52bdc7;
  -webkit-text-fill-color: transparent;
}
.gallery-cont,
.inner-section {
  margin-top: 100px;
}
:root {
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 15s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}
#footer {
  background: #13303c;
  background-image: unset;
  position: relative;
  padding: 0;
}
.footer-bottom {
  margin-top: 0;
  padding-top: 20px;
  border-top: none;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
#footer p.text,
ul.wg-menu li a {
  color: #ffffffda !important;
  font-size: 15px;
}
#footer p.text,
.footer-title {
  font-weight: 400;
}
#footer p.text {
  line-height: 24px;
  margin-top: -4px;
  margin-bottom: 21px;
}
.footer-head p,
.social-icon-custm a {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
}
.social-icon-custm a:hover {
  color: #fff;
  cursor: pointer;
}
@media (max-width: 500px) {
  .wg-banner-1 .content .title h2,
  .wg-result .content .title h2 {
    color: #fff;
    font-size: 30px;
    line-height: 38px;
  }
}
@media (max-width: 575px) {
  .gallery-cont .sub-section h3 {
    font-size: 32px;
  }
  .custom-underline,
  .sub-section h3 {
    font-size: 24px;
  }
  /* .wg-create-design {
    padding: 50px 0 10px !important;
  } */
  .wg-banner-1 .content .title h2,
  .wg-banner-1 .content p,
  .wg-result .content .title h2,
  .wg-result .content p {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .header-right,
  .star-icon .star-1,
  .star-icon .star-2 {
    display: none;
  }
  .rocket-gif {
    width: 28px !important;
  }
  .cust-padding,
  .cust-padding-2,
  .section-padding {
    padding: 50px 0;
  }
  .section-head {
    font-size: 30px;
    line-height: 38px;
  }
  .offer-sec {
    padding-top: 40px;
  }
  .expertise-card div {
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
  }
  .vic-card .card-body {
    padding: 0 0 20px !important;
  }
  .tf-button {
    background-color: #ffbf00;
    border: 2px solid #ffbf00;
    border-radius: 50px;
    color: #000;
    padding: 12px 20px !important;
    height: auto !important;
    max-width: 100%;
  }
  .cust-slider-cont .card {
    margin-right: 0;
    padding: 0 0 20px;
  }
  .wg-banner-1 .content .title h2,
  .wg-result .content .title h2 {
    color: #fff;
    font-size: 30px !important;
    line-height: 38px !important;
  }
  .custom-underline::before {
    width: 100% !important;
    top: 29px !important;
    left: 0 !important;
    height: 7px !important;
  }
  .idea-booster-page .footer-bottom p {
    font-size: 14px !important;
  }
  .wg-banner-1 {
    overflow: hidden;
    padding-top: 50px;
  }
  .header-inner {
    justify-content: center;
  }
  .testi-card-custom {
    margin-right: 0;
  }
  .language-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 170px;
    height: 190px;
    text-align: center;
    border-radius: 16px;
    padding: 30px;
  }
}
.back-top,
.thankyou-page-wrapper .modal-backdrop {
  display: none;
}
@media (max-width: 767px) {
  .wg-banner-1 {
    padding: 50px 0;
  }
  .arrow-hero,
  .guide-1,
  .guide-2,
  .star-icon .star-2 {
    position: absolute;
  }
  .arrow-hero {
    top: 1px;
    margin-left: 78px;
    width: 150px;
    transform: rotate(350deg);
  }
  .cust-head-para {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: 30px !important;
  }
  .vdo-sec-txt,
  p {
    font-size: 18px !important;
    line-height: 1.2 !important;
  }
  .section-head {
    font-size: 32px;
    line-height: 42px;
  }
  .star-icon {
    display: none;
  }
  .wg-banner-1 .content .title h2,
  .wg-result .content .title h2 {
    color: #fff;
    font-size: 32px;
    line-height: 42px;
  }
  .star-icon .star-1,
  .star-icon .star-2 {
    width: 40px;
  }
  .star-icon .star-1 {
    position: absolute;
    bottom: -10px;
    left: 130px;
  }
  .head-gradient {
    font-size: 24px;
    line-height: 30px;
  }
  .heading-section .sub-title {
    font-size: 16px !important;
    line-height: 24px;
  }
}
@media (max-width: 1199px) {
  .cust-width {
    padding: 0 !important;
  }
  .offer-sec {
    padding-top: 70px;
  }
  .wg-banner-1 .content .title h2,
  .wg-banner-1 .content p,
  .wg-result .content .title h2,
  .wg-result .content p {
    padding-left: 70px;
    padding-right: 70px;
  }
  .cust-head-para {
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 50px !important;
  }
  .rocket-gif {
    width: 45px;
  }
}
.back-top {
  z-index: 8;
  color: #000;
  background: #52bdc7;
  font-size: 18px;
  height: 36px;
  width: 36px;
  font-weight: 500;
  line-height: 36px;
  padding: 0 12px;
  border-radius: 5px 0 0 5px;
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
}
#header-main {
  position: fixed;
  bottom: 100px;
  right: 0;
  cursor: pointer;
}
.gradient-secondary {
  background: padding-box text #13303c;
}
.offer-card2 {
  background: #f5f5f5;
}

.text-yellow {
  fill: #febe27;
}
.rating-wrapper {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
}
.job-role {
  text-align: left;
}
.testimonial-wrapper {
  display: flex;
}
@media (max-width: 768px) {
  .testimonial-wrapper {
    display: block;
  }
  .testimonial-content,
  div#video-container {
    width: 100%;
  }
  .sub-section h3 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 600;
  }
  .cust-padding,
  .cust-padding-2,
  .cust-padding-3,
  .section-padding {
    padding: 50px 0;
  }
  .border-card-2:before,
  .border-card-5:before {
    display: none;
  }
}

.form-control,
.react-tel-input .form-control {
  border: 1px solid #b5b5b5;
}
.player-button {
  position: absolute;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.player-button:hover {
  color: #52bdc7;
}
.pdt-15 {
  padding-top: 15px;
}
.pdb-12 {
  padding-bottom: 12px;
}
.thankyou-page-wrapper {
  height: 100%;
  min-height: calc(100vh - 182px);
}
.p-100 {
  padding: 80px;
}
.bg-safon {
  background-color: #52bdc7;
}
.home-cta {
  border-radius: 20px;
  padding: 60px;
}
.home-cta img {
  margin-top: -134px;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0 10px 22px;
}
.inner {
  padding: 150px 0 80px;
}
.bg-light {
  background-color: #f5f5f5;
}

.error-msg {
  position: absolute;
  font-size: 13px;
}

.testimonial-content p.testi-para {
  /* max-height: 224px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 9; */
  -webkit-box-orient: vertical;
}

.scroll-up a {
  position: fixed;
  bottom: 10%;
  right: 10px;
  background: #52bdc7;
  width: 2em;
  height: 2em;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  text-align: center;
  line-height: 2.2;
  color: #1c1c1c;
  z-index: 99;
  right: 0;
}

.scroll-up a i {
  font-size: 1.2em;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

@media screen and (min-width: 996px) {
  .sectiontitle-element--center {
    max-width: 65%;
    margin: 0 auto;
    text-align: center;
  }
}

.app-title:hover {
  color: #030014;
}
.app-title {
  color: #030014;
  font-weight: 600;
  text-decoration: none;
}

@media screen and (max-width: 576px) {
  .head-gradient {
    font-size: 24px !important;
    background: #52bdc7;
    -webkit-background-clip: text;
    line-height: 28px !important;
    font-weight: 600;
  }
}
